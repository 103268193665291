<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card>
        <v-card-title>
          <div>{{ $t("account.setting.title") }}</div>
        </v-card-title>
        <v-card-subtitle class="grey--text text--darken-1">{{ $t("account.setting.description") }}</v-card-subtitle>
        <v-card-text class="mt-4">
          <v-switch
            v-model="form.notification.message"
            dense
            :label="$t('account.setting.fields.notification.conversation.title')"
          ></v-switch>
          <v-switch
            dense
            v-model="form.notification.conversation"
            :label="$t('account.setting.fields.notification.conversation.title')"
          ></v-switch>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn type="submit" color="primary" text :loading="loading.save">
            {{
            $t("btn.update")
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";
export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  created() {
    this.initForm();
  },
  data: () => ({
    loading: {
      save: false
    },
    form: {
      notification: {
        message: true,
        conversation: true
      }
    }
  }),
  validations: { form: {} },
  methods: {
    initForm() {
      const settings = this.$auth.user().settings;
      this.form = {
        notification: {
          message: this.$utils.getPropertyValue(
            settings,
            "notification.message",
            false
          ),
          conversation: this.$utils.getPropertyValue(
            settings,
            "notification.conversation",
            false
          )
        }
      };
    },
    getPath() {
      return "users.settings";
    },
    getData(form) {
      return form;
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
      this.$auth.fetch();
    },
    getMessages() {
      return {
        200: this.$t("account.setting.edit.success"),
        400: true
      };
    }
  }
};
</script>