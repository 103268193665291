<template>
  <div>
    <setting></setting>
  </div>
</template>


<script>
import Setting from './../../components/account/setting';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('account.setting.title'),
    };
  },
  components: {
    Setting
  }
}
</script>